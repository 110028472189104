import whitelistAddresses from "./whitelist.json";
import { MerkleTree } from "merkletreejs";
import { keccak256 } from "js-sha3";

global.Buffer = global.Buffer || require("buffer").Buffer;

export class Whitelist {
  private merkleTree!: MerkleTree;

  private getMerkleTree(): MerkleTree {
    if (!this.merkleTree) {
      const leafNodes = whitelistAddresses.map((addr: string) =>
        keccak256(addr)
      );

      this.merkleTree = new MerkleTree(
        leafNodes.map((leaf) => this.hexToBuffer(leaf)),
        keccak256,
        {
          sortPairs: true,
        }
      );
    }

    return this.merkleTree;
  }

  private hexToBuffer(hex: string): Buffer {
    return Buffer.from(hex, "hex");
  }

  public getProofForAddress(address: string): string[] {
    return this.getMerkleTree().getHexProof(keccak256(address));
  }

  public getRawProofForAddress(address: string): string {
    return this.getProofForAddress(address).join("");
  }

  public contains(address: string): boolean {
    const leafHash = keccak256(address);
    const leafIndex = this.getMerkleTree().getLeafIndex(
      this.hexToBuffer(leafHash)
    );
    return leafIndex !== null && leafIndex !== undefined && leafIndex >= 0;
  }
}

const whitelist = new Whitelist();
export default whitelist;

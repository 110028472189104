import React from "react";
import { Button, Flex, Image, Link } from "@chakra-ui/react";

import Etherscan from "./assets/social-media-icons/etherscan_32x32.png";
import Twitter from "./assets/social-media-icons/twitter_32x32.png";
import Opensea from "./assets/social-media-icons/opensea_32x32.png";

const NavBar = ({ accounts, setAccounts }) => {
  const isConnected = Boolean(accounts[0]);

  async function connectAccount() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccounts(accounts);
      } catch (error) {
        console.error("Error connecting account:", error);
      }
    }
  }

  function disconnectAccount() {
    setAccounts([]);
  }

  return (
    <Flex justify="space-between" align="center" padding="30px">
      {/* Left Side - Social Media Icons */}
      <Flex justify="space-around" width="40%" padding="0 75px">
        <Link href="https://testnet.blastscan.io/address/0x156d34f6b94bafbd59b47913856bbfa11804d9ec">
          <Image src={Etherscan} boxSize="42px" margin="0 15px" />
        </Link>
        <Link href="https://element.market/collections/cybermurai-v2?search[toggles][0]=ALL">
          <Image src={Opensea} boxSize="42px" margin="0 15px" />
        </Link>
        <Link href="https://twitter.com/Cybermurai">
          <Image src={Twitter} boxSize="42px" margin="0 15px" />
        </Link>
      </Flex>

      {/* Right Side - Sections and Connect/Disconnect */}
      <Flex justify="space-around" align="center" width="40%" padding="30px">
        {/* Connect/Disconnect Button */}
        {isConnected ? (
          <Button
            backgroundColor="#D6517D"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            margin="0 15px 0 0px"
            onClick={disconnectAccount}
          >
            Disconnect
          </Button>
        ) : (
          <Button
            backgroundColor="#D6517D"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            margin="0 15px 0 0px"
            onClick={connectAccount}
          >
            Connect
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;

import React, { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import Cybermuraiv2 from "./Cybermuraiv2.json";
import Whitelist from "./Whitelist";

const chainId = 168587773; // Default Chain ID for the Network
const networkName = "Blast Sepolia"; // Default Network Name

const cybermuraiv2Address = "0x156d34f6B94bAfBD59b47913856BBfa11804d9eC";

const MainMint = ({ accounts }) => {
  const [mintAmount, setMintAmount] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [cost, setCost] = useState(null);
  const [maxLimitPerWallet, setMaxLimitPerWallet] = useState(1);
  const [maxSupply, setMaxSupply] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [whitelistMintEnabled, setWhitelistMintEnabled] = useState(false);
  const isConnected = Boolean(accounts[0]);

  useEffect(() => {
    const fetchData = async () => {
      if (!window.ethereum) {
        return;
      }

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();

        // Check if connected network matches the expected network
        if (network.chainId !== chainId) {
          setError(`Please connect to the ${networkName} network.`);
          return;
        }

        const contract = new ethers.Contract(
          cybermuraiv2Address,
          Cybermuraiv2.abi,
          provider
        );
        const _cost = await contract.cost();
        setCost(_cost);
        const _whitelistMintEnabled = await contract.whitelistMintEnabled();
        setWhitelistMintEnabled(_whitelistMintEnabled);
        if (_whitelistMintEnabled) {
          setMaxLimitPerWallet(await contract.maxLimitPerWalletWL());
        } else {
          setMaxLimitPerWallet(await contract.maxLimitPerWalletPublic());
        }
        setMaxSupply(await contract.maxSupply());
        setTotalSupply(await contract.totalSupply());
      } catch (err) {
        console.error("Failed to fetch data: ", err);
      }
    };

    fetchData();
  }, []);

  const handleMint = async () => {
    if (!window.ethereum) {
      setError(
        "Web3 provider not found. Make sure you have a web3-enabled browser."
      );
      return;
    }

    // Ensure that the user is connected to the correct network
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();

    if (network.chainId !== chainId) {
      setError(`Please connect to the ${networkName} network.`);
      return;
    }

    try {
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        cybermuraiv2Address,
        Cybermuraiv2.abi,
        signer
      );

      const userAddress = await signer.getAddress();

      // Perform whitelist check only if whitelistMintEnabled is true
      if (whitelistMintEnabled) {
        const isWhitelisted = Whitelist.contains(userAddress);
        if (!isWhitelisted) {
          setError("You are not whitelisted for minting.");
          return;
        }
      }

      const totalSupply = await contract.totalSupply();
      if (totalSupply.toNumber() >= maxSupply) {
        setError("Sold out");
        return;
      }

      const userMintCount = await contract.mintCount(userAddress);
      if (userMintCount >= maxLimitPerWallet) {
        setError("You have reached the maximum amount of mints.");
        return;
      }

      const value = cost.mul(mintAmount);
      const response = await contract.mint(BigNumber.from(mintAmount), {
        value,
      });
      console.log("Mint transaction successful: ", response);
      setSuccess("Mint transaction successful");
      setError(null);

      if (totalSupply.toNumber() + mintAmount >= maxSupply) {
        setMaxLimitPerWallet(0);
      }
    } catch (err) {
      console.error("Mint transaction error: ", err);
      setError("Mint transaction error. Please try again.");
      setSuccess(null);
    }
  };

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if (mintAmount >= maxLimitPerWallet) return;
    setMintAmount(mintAmount + 1);
  };

  return (
    <Flex justify="center" align="center" paddingBottom="0px">
      <Box width="520px" overflowY="auto" height="100%">
        <div>
          <Text fontSize="48px" textShadow="0 5px #000000">
            Cybermurai
          </Text>
          <Text
            fontSize="20px"
            letterSpacing="-5.5%"
            fontFamily="Roboto Mono"
            textShadow="0 2px 2px #000000"
          >
            It's 2069. Can the Cyber Samurai's save humanity from destructive
            NFT speculation? Mint Cybermurai to find out.
          </Text>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{success}</p>}
        </div>

        {isConnected ? (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <p>
                  {totalSupply
                    ? `${totalSupply.toString()}/${maxSupply.toString()} Minted`
                    : "-"}
                </p>
              </div>
            </div>
            <Flex align="center" justify="center">
              <Button
                backgroundColor="#D6517D"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handleDecrement}
              >
                -
              </Button>
              <Input
                readOnly
                fontFamily="inherit"
                width="100px"
                height="40px"
                textAlign="center"
                paddingLeft="19px"
                marginTop="10px"
                type="number"
                value={mintAmount}
              />
              <Button
                backgroundColor="#D6517D"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handleIncrement}
              >
                +
              </Button>
            </Flex>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                Total:{" "}
                {cost
                  ? ethers.utils.formatEther(cost.mul(mintAmount)) + " ETH"
                  : "-"}
              </p>
            </div>
            <Button
              backgroundColor="#D6517D"
              borderRadius="5px"
              boxShadow="0px 2px 2px 1px #0F0F0F"
              color="white"
              cursor="pointer"
              fontFamily="inherit"
              padding="15px"
              marginTop="10px"
              onClick={handleMint}
            >
              Mint Now
            </Button>
          </div>
        ) : (
          <Text
            marginTop="70px"
            fontSize="20px"
            letterSpacing="-5.5%"
            fontFamily="Roboto Mono"
            textShadow="0 3px #000000"
            color="#D6517D"
          >
            Connect Wallet to Mint
          </Text>
        )}
        <footer>
          <p>&copy; 2023 Cybermurai. All rights reserved.</p>
        </footer>
      </Box>
    </Flex>
  );
};

export default MainMint;
